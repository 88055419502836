
  import {qrPubCssTempList} from '@/api/template'
  import {getPromptPerson, txtQrImg} from '@/api/ai/sd.js'
  import eventBus from '@/utils/event-bus'
  import { queryTrans } from "@/api/mirTranslate";

  export default {
    name: 'BeautifyQr',
    components: {},
    props: {
      baseImg: String,
      qrText: String
    },
    data() {
      return {
        idisCode: '',
        isEdit: false,
        qrData: this.qrText,
        qrBaseImg: this.baseImg,
        downLoadOrSaveVisible: false,
        upBgImg: '',
        upBgImgOp: 0.5,
        detectSpecial: false,
        bgOpacity: 1,
        eyeModel: '',
        qrParam: {},
        showBeautifyDialog: false,
        prompt: '',
        promptLabels: {
          person: [],
          env: [],
          scene: []
        },
        loading: false
      }
    },
    computed: {},
    created() {
      getPromptPerson().then(res => {
        this.promptLabels.person = res.rows
      })
    },
    mounted() {
      const _this = this
      this.downLoadSet = JSON.parse(localStorage.getItem('downLoadSet')) ? JSON.parse(
          localStorage.getItem('downLoadSet'))
        : {
          size: 300,
          type: 'png'
        }
    },
    methods: {
      async genAiQrCode() {
        const that = this
        let base64Img = this.baseImg
        if (base64Img.startsWith('data:image/png;base64,')) {
          base64Img = base64Img.split(',')[1]; // 提取 base64 数据部分
        } else {
          this.$message({
            type: 'error',
            duration: '5000',
            message: '二维码图片获取失败'
          })
          return
        }
        this.loading = true
        let prompt = this.prompt
        if (this.containsChinese(prompt)) {
          const data = await queryTrans(prompt)
          if(data){
            that.sendAiQrCode(data[0].translations[0].text, base64Img)
          }else{
            this.$message({
              type: 'error',
              duration: '5000',
              message: '系统错误'
            })
            this.loading = false
          }
        } else {
          this.sendAiQrCode(prompt, base64Img)
        }
      },
      sendAiQrCode(prompt, base64Img) {
        txtQrImg({
          prompt,
          promptCn: this.prompt,
          base64Img: base64Img
        }).then(res => {
          this.loading = false
          this.qrBaseImg = 'data:image/png;base64,' + res.data
        }).catch(() => {
          this.loading = false
        })
      },
      containsChinese(str) {
        const regex = /[\u4e00-\u9fa5]/; // 匹配中文字符的正则表达式
        return regex.test(str);
      },
      openLogin() {
        eventBus.$emit('loginOrRegister', 'openLogin')
      },
      openDialog(idisCode) {
        this.showBeautifyDialog = true
        this.qrData = this.qrText
        this.qrBaseImg = this.baseImg
        this.loading = false
        this.prompt = ''
        if (idisCode) {
          this.idisCode = idisCode
        } else {
          this.idisCode = ''
        }
        // this.$nextTick( () => {
        //   document.getElementById( 'mySection' ).style.overflowY = 'hidden'
        // } )
      },
      handleQrClose() {
        this.showBeautifyDialog = false
        document.getElementById('mySection').style.overflowY = 'auto'
      },
      qrPubCssTempList() {
        qrPubCssTempList().then(res => {
          this.pubQrList = res.rows
        })
      },
      downloadQr() {
        const aLink = document.createElement('a')
        aLink.style.display = 'none'
        aLink.href = this.qrBaseImg
        aLink.download = '二维码.png'
        document.body.appendChild(aLink)
        aLink.click()
        document.body.removeChild(aLink)
      },
      getQrBase64() {
      },
      enterPrompt(prompt) {
        this.prompt = prompt
        this.genAiQrCode()
      }
    }
  }

